import styled from 'styled-components';
import BannerBG from 'common/assets/image/saasModern/banner_background.jpg';

export const BannerWrapper = styled.section`
  padding: 25px 0 50px 0;
  background-size: cover;
  background-position: top center;

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
`;
